import React, { Component } from 'react';
import { addDays } from 'date-fns';
import moment from 'moment';
import _ from 'lodash';

import {
  //ranges,
  customRanges,
} from '../../../../Components/CustomDatePicker/StaticRanges';
import { getOrganizationDate } from '../../../../Helpers/Date/OrganizationDate';
import Field from '../../../../Components/Form/Field/Field';

class InvoiceTerm extends Component {
  state = {
    selectedTerm: this.props.selectedInvoiceTerm,
    selectedDate: getOrganizationDate(),
    options: [],
  };

  componentDidUpdate(prevProps) {
    if (this.state.selectedTerm.value !== this.props.selectedInvoiceTerm.value)
      this.setState({ selectedTerm: this.props.selectedInvoiceTerm });
    if (!_.isEqual(prevProps.invoiceTermsList, this.props.invoiceTermsList)) {
      this.updateOptions(this.props.invoiceTermsList);
    }
  }

  componentDidMount() {
    this.updateOptions(this.props.invoiceTermsList);
  }

  updateOptions(list) {
    if (
      list.some(
        (term) =>
          term.value === this.props.selectedInvoiceTerm.value &&
          term.name === this.props.selectedInvoiceTerm.name
      )
    ) {
      this.setState({ options: list });
    } else {
      this.setState({ options: list });
    }
  }

  handleInvoiceTermChange = (option) => {
    if (option !== null && option.value !== 'createNew') {
      this.setState({ selectedTerm: option }, () => {
        this.handleDate(option);
      });
    } else {
      this.setState({ selectedTerm: {} }, () => {
        this.props.handleInvoiceTerms(option);
      });
    }
  };

  handleDate(option) {
    let value = option.value;
    let date = this.props.invoiceDate;
    switch (value) {
      case 'custom':
        break;
      case 'this month':
        date = moment(customRanges(this.props.invoiceDate).endOfMonth);
        break;
      case 'next month':
        date = moment(customRanges(this.props.invoiceDate).endOfNextMonth);
        break;
      case 'receipt':
        date = moment(customRanges(this.props.invoiceDate).endOfToday);
        break;
      default:
        date = moment(addDays(this.props.invoiceDate, value));
    }
    this.setState({ selectedTerm: option });
    this.props.handleInvoiceTerms(option);
    this.props.handleInvoiceDate(date);
  }

  renderInvoiceTerm() {
    const { selectedTerm, options } = this.state;
    const { className, name, size } = this.props;
    return (
      <Field
        type="dropdown"
        className={className}
        name={name}
        size={size ? size : 'medium'}
        value={selectedTerm ? selectedTerm.value : null}
        options={options}
        dropdownValue="value"
        dropdownLabel="name"
        handleChange={(option) => {
          this.handleInvoiceTermChange(option);
        }}
        addButton
      />
    );
  }

  render() {
    return this.renderInvoiceTerm();
  }
}

export default InvoiceTerm;

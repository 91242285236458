import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { withFormik, FieldArray } from 'formik';
import DeleteIcon from '../../../Assets/General/DeleteIcon';
//import moment from 'moment';
import { getDiscountOptions } from '../../../Constants';
import {
  restRequest,
  checkError,
  pageIdentify,
  getObjFromLS,
  setObjInLS,
  confirmationAlert,
  GetDateString,
} from '../../../Helpers';
import {
  required,
  ValidateDate,
  ValidateRequired,
  ValidateAmountNumber,
} from '../../../Helpers/Validator';
import { Field, AddItem, ErrorFocus, FormAction, Form } from '../';
import { getError, validate, formSubmit } from '../FormHelper';
import { ListTableImageColumn, Switch, Loader, Quantity } from '../../';
import './CreditNoteForm.css';
import { validateCreditNotesForm } from '../../../Helpers/FormValidator';
import DragDropItems from '../../../Common/DragDrop';
import { Draggable } from 'react-beautiful-dnd';
import { MdDragIndicator } from 'react-icons/md';

let saleOrdersListEnhancer;
let returnReceivesListEnhancer;
let salesReturnListEnhancer;
let allResult;
let total_Deduction;
let total_Credits;
let allAdjustments = 0;
let isSaleOrderEnhancer;
const getYupValidationSchema = () => {
  return Yup.object().shape({
    creditNoteNo: required(ValidateRequired),
    //salesOrderId: required(ValidateNumber),
    salesReturnDate: required(ValidateDate),
    // items: Yup.array()
    //   .of(
    //     Yup.object().shape({
    //       // name: ValidateRequired,  //removed the validation for item required
    //       quantity_processed: required(moreThanZero(ValidateMax10Billion)),
    //     })
    //   )
    items: Yup.array()
      .of(
        Yup.lazy((item) => {
          return Yup.object().shape({
            //name: ValidateRequired,  //removed the validation for item required
            // quantity_processed: required(moreThanZero(ValidateMax10Billion)),
            quantity_processed: ValidateAmountNumber(
              item.quantity_processed,
              item.actualQuantity
            ),
          });
        })
      )
      .min(1, 'At least one item is required'),
  });
};

const formEnhancer = withFormik({
  validate: validate(getYupValidationSchema),
  mapPropsToValues: ({
    saleorderInfo,
    invoiceInfo,
    creditNoteNo,
    ...props
  }) => ({
    ...saleorderInfo,
    invoiceInfo,
    creditNoteNo,
    ...props,
  }),
  mapPropsToStatus: (props) => ({
    ...props,
    itemsList: props.itemsList,
    emptyItem: props.emptyItem,
  }),
  handleSubmit: (values, bag) => {
    var applyBreak = false;
    if (values.items.length > 1) {
      values.items.forEach(function (item) {
        if (item.id === null) {
          applyBreak = true;
        }
      });
    } else if (values.items.length === 1) {
      values.items.forEach(function (item) {
        if (item.id === null && item.quantity === 0) {
          applyBreak = true;
        }
      });
    }

    if (applyBreak === false) {
      handleFormSubmit(values, bag);
    } else {
      displayError(values, bag);
    }
  },
});

const displayError = (values, { setSubmitting, props: { handleToast } }) => {
  handleToast(
    'Form will not Submit with Empty Item, Please Fill or Delete Empty Item before pressing the Save button',
    'error'
  );
  setSubmitting(false);
};

const handleFormSubmit = (
  values,
  {
    setSubmitting,
    props: {
      submitRequestType,
      submitRequestUrl,
      successMessage,
      inModal,
      close,
      onSubmit,
      history,
      handleToast,
      type,
      handlePrompt,
      setPrompt,
    },
  }
) => {
  let directlySold = values.items.some(function (el, i) {
    return el.salesOrderItemDetailId === null ? true : false;
  });
  const {
    location: { search },
  } = history;
  let payload = {
    invoice_id: allResult.invoice.id,
    credit_note_no:
      submitRequestType() !== 'put'
        ? allResult.credit_notes_no
        : allResult.invoice.creditNote.id,
    customer_id: allResult.invoice.customer_id,
    credit_note_date: values.salesReturnDate.format('YYYY-MM-DD 00:00:00'),
    sale_order_id:
      directlySold === true
        ? null
        : isSaleOrderEnhancer
        ? values.salesOrderIdGeniuine
        : null,
    deductions: total_Deduction,
    issued_credits: total_Credits,
    directly_sold: directlySold,
    items:
      values.items.length === 1 && values.items[0].id === null
        ? []
        : values.items.map((item, index) => ({
            invoice_id: allResult.invoice.id,
            sort_order: index,
            sale_order_id:
              directlySold === true
                ? null
                : isSaleOrderEnhancer
                ? values.salesOrderIdGeniuine
                : null,
            sale_return_id:
              directlySold === true
                ? null
                : isSaleOrderEnhancer
                ? values.salesreturn
                : null,
            return_receive_id:
              directlySold === true
                ? null
                : isSaleOrderEnhancer
                ? values.returnreceive
                : null,
            item_id: item.id,
            warehouse_id: item.res_warehouse_id,
            credits: item.quantity ? item.quantity : 0,
            adjustement_criteria: item.criteria
              ? item.criteria
              : values.transactionDiscountType,
            //adjustment : item.adjustment ? item.adjustment : prefrenceEnhancer.adjustment,
            adjustment: item.adjustment ? item.adjustment : 0,
            adjustment_amount: item.quantity,
            quantity_processed: item.quantity_processed,
          })),
  };
  restRequest(submitRequestType(), submitRequestUrl(), payload)
    .then(() => {
      handlePrompt(false);
      setPrompt(false);
      successMessage();
      if (inModal) {
        // close()
        // onSubmit();
        let dataFromLS = getObjFromLS('module');
        if (dataFromLS) {
          setObjInLS('module', {
            ...dataFromLS,
            queryParam: `${
              dataFromLS.queryParam ? dataFromLS.queryParam + '&' : '?'
            }creditnotes=true`,
          });
        } else {
          let obj = {
            moduleName: 'invoices',
            urlPath: window.location.pathname,
            queryParam: `${
              window.location.search ? window.location.search + '&' : '?'
            }creditnotes=true`,
          };
          setObjInLS('module', obj);
        }
        return search ? history.push('/r') : onSubmit();
      } else if (!pageIdentify(window.location.pathname)) {
        let dataFromLS = getObjFromLS('module');
        if (dataFromLS === null || dataFromLS.urlPath !== '/creditnotes') {
          setObjInLS('module', {
            ...dataFromLS,
            moduleName: 'creditnotes',
            action: type === 'add' ? 'add' : 'justedit',
            queryParam: '',
          });
          sessionStorage.setItem('fullscreen', false);
          return history.push('/creditnotes');
          // window.location.href = '/creditnotes';
        } else {
          setObjInLS('module', {
            ...dataFromLS,
            action: type === 'add' ? 'add' : 'justedit',
            queryParam: '',
          });
          sessionStorage.setItem('fullscreen', false);
          return history.push('/r');
        }
      } else {
        let dataFromLS = getObjFromLS('module');
        setObjInLS('module', {
          ...dataFromLS,
          action: type === 'add' ? 'add' : 'justedit',
          queryParam: dataFromLS.queryParam,
        });
        return history.push('/r');
      }
    })
    .catch((error) => {
      checkError(error, handleToast);
      setSubmitting(false);
    });
};

const CreditNoteForm = ({
  // props from formik
  values,
  status,
  handleSubmit,
  handleChange,
  handleBlur,
  setFieldValue,
  setStatus,
  setValues,
  isSubmitting,
  submitCount,
  currency,
  errors,
  touched,
  setFieldTouched,

  // custom props
  title,
  shipmentId,
  inModal,
  returnReason,
  addBackToStock,
  warehouseListRecieve,
  genericWarehouseId,
  warehouseResult,
  contactPersonId,
  contactPerson,
  type,
  directSoldItemsArray,
  directSoldItemsFlag,
  saleOrdersList,
  preferences,
  alResult,
  isSaleOrder,
  returnReceivesList,
  salesReturnList,
  allContactsList,
  salesOrderList,
  invoiceNumber,
  contactDisplayName,
  history,
  close,
  handlePrompt,
  ...props
}) => {
  //salesReturnDate

  // prefrenceEnhancer = preferences;
  // console.log({ values, status });
  const { itemsList, emptyItem } = status;
  const [staticVal, setStaticValues] = useState({});
  const [showPrompt, setShowprompt] = useState(false);
  useEffect(() => {
    setStaticValues(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { checkout } = validateCreditNotesForm(
      values,
      {
        ...staticVal,
        ...(inModal && { salesOrderIdGeniuine: saleOrdersList?.[0].id }),
        ...(inModal && { salesreturn: salesReturnList?.[0].id }),
        ...(inModal && { returnreceive: returnReceivesList?.[0]?.id }),
        transactionDiscount: preferences?.adjustment,
        transactionDiscountType: preferences?.adjustement_criteria,
      },
      inModal
    );
    if (!checkout) props.setPrompt();
    else props.setPrompt(false);

    if (!checkout && inModal) handlePrompt();
    else handlePrompt(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, staticVal, showPrompt]);

  const handleShowPrompt = () => {
    setShowprompt(!showPrompt);
  };

  // postWarehouses = warehouseListRecieve;
  var packageFakeItem = false;
  const handleSalesOrderChange = async (selected) => {
    allResult.invoice.saleOrder.forEach((salesorder) => {
      if (salesorder.id === selected.id) {
        salesReturnListEnhancer = salesorder.saleReturn.map((salesreturn) => ({
          id: salesreturn.id,
          name: salesreturn.sales_return_no,
        }));

        salesorder.saleReturn.forEach((salesreturn) => {
          if (salesreturn.id === salesReturnListEnhancer[0].id) {
            returnReceivesListEnhancer = salesreturn.return_receives.map(
              (salesorder) => ({
                id: salesorder.id,
                name: salesorder.return_receives_no,
              })
            );
          }
        });
      }
    });
    setValues({
      ...values,
      salesOrderIdGeniuine: selected.value,
      salesreturn: salesReturnListEnhancer[0].id,
      returnreceive: returnReceivesListEnhancer[0].id,
    });
    calcDeduction();
  };
  const handleSalesReturnChange = async (selected) => {
    allResult.invoice.saleOrder.forEach((salesorder) => {
      if (salesorder.id === values.salesOrderIdGeniuine) {
        salesorder.saleReturn.forEach((salesreturn) => {
          if (salesreturn.id === selected.id) {
            returnReceivesListEnhancer = salesreturn.return_receives.map(
              (salesorder) => ({
                id: salesorder.id,
                name: salesorder.return_receives_no,
              })
            );
          }
        });
      }
    });
    setValues({
      ...values,
      salesreturn: selected.value,
      returnreceive: returnReceivesListEnhancer[0].id,
    });
    calcDeduction();
  };
  const handleReturnReceiveChange = async (selected) => {
    setValues({
      ...values,
      returnreceive: selected.value,
    });
    calcDeduction();
    renderSelectedItemsTable();
  };

  const handleSelectedItem = (option, index) => {
    const newItem = { ...option };
    const updatedItems = [
      ...Object.assign([...values.items], { [index]: newItem }),
    ];
    setFieldValue('items', updatedItems);
    setStatus({
      ...status,
      itemsList: status.itemsList.filter((item) => item.id !== option.id),
    });
  };
  const renderFooter = () => {
    return (
      <div
        className="float-left w-100 mt_3 summary_table"
        // style={{ paddingRight: '2%' }}
      >
        <hr className="margin float-left w-100"></hr>
        <div className="float-left w-70 p_10">
          <div className="float-left w-100 so-mg-1">
            <span className="float-left" style={{ marginRight: '10px' }}>
              Invoice Amount
            </span>
            <span className="float-left">
              {currency.symbol}
              {values?.invoice?.total.toFixed(2)}
            </span>
          </div>
          <div className="float-left w-100">
            <span className="float-left" style={{ marginRight: '10px' }}>
              Payments Made
            </span>
            <span className="float-left">
              {currency.symbol}
              {values?.invoice?.total_payments.toFixed(2)}
            </span>
          </div>
        </div>
        <div className="float-left w-30 p_10 pr-0">
          <div className="float-left w-100 so-mg-1">
            <span className="float-left">Issuable Credits</span>
            <span className="float-right bold">
              {currency.symbol}
              {values?.invoice?.total_issue_able_credits.toFixed(2)}
            </span>
          </div>
          <div className="float-left w-100 so-mg-1">
            <span className="float-left">Total Deductions</span>
            <span className="float-right bold">
              {currency.symbol}
              {total.toFixed(2)}
            </span>
          </div>
        </div>

        <hr className="margin float-left w-100" />
        <div className="float-left w-100">
          <div className="float-left w-70 p_10"></div>
          <div className="baseline float-left w-30 px_10 d-flex flex-wrap align-center space-between cn-totals">
            <span className="semi-bold float-left flex-25">Total Credits</span>
            {/* <span className="total-price--value float-right mr-1">{currency.symbol}{credits.toFixed(2)}</span> */}
            <span
              className={`text-right word-break total-price--value float-right ${
                credits.toFixed(2) < 0 && 'red_color'
              }`}
            >
              {currency.symbol}
              {credits.toFixed(2)}
            </span>
          </div>
        </div>
        <hr className="margin float-left w-100" />
        <hr className="margin float-left w-100" />
      </div>
    );
  };

  const renderAddItem = () => {
    const { items } = values;
    items.forEach((item) => {
      if (item.id == null) {
        packageFakeItem = true;
      }
    });
    if (packageFakeItem) {
      return '';
    } else {
      return (
        <AddItem
          onClick={() => setFieldValue('items', [...values.items, emptyItem])}
        />
      );
    }
  };

  const handleItemQuantityChange = (quantityValue, index) => {
    // Price_Quantity_Limit

    // price and quantity limit from 5 digits to 10 digits
    let quantity = quantityValue.floatValue;
    if (quantity === undefined) {
      quantity = 0;
    }
    if (quantity > 0) {
      let quantString = quantity.toString();
      if (quantString.length > 10) {
        quantString = quantString.slice(0, 10);
      }
      quantity = parseFloat(quantString);
    }
    setFieldValue('items[' + index + '].quantity', quantity);
  };

  const handleProcessedItemQuantityChange = (quantityValue, index, item) => {
    //console.log("quantityValue", quantityValue)
    // Price_Quantity_Limit

    // price and quantity limit from 5 digits to 10 digits
    let quantity = quantityValue.floatValue;
    if (quantity === undefined) {
      quantity = 0;
    }
    if (quantity > 0) {
      let quantString = quantity.toString();
      if (quantString.length > 10) {
        quantString = quantString.slice(0, 10);
      }
      quantity = parseFloat(quantString);
    }
    setFormSubmitting(false);
    setFieldValue('items[' + index + '].quantity_processed', quantity);
    if (checkItems(index, quantity) === false) {
      setFieldValue('isSubmitting', false);
      setFormSubmitting(false);
    } else {
      setFormSubmitting(true);
    }
  };

  const checkItems = (selIndex, selQuantity) => {
    const { items } = values;
    var ItemPassed = true;
    items.forEach((item, index) => {
      if (index === selIndex && selQuantity >= 0) {
        item.quantity_processed = selQuantity;
      }
    });
    const newitems = values.items;
    newitems.forEach((newitem, index) => {
      if (newitem.quantity_processed <= 0) {
        ItemPassed = false;
        return ItemPassed;
      }
    });
    return ItemPassed;
  };

  // Price_Quantity_Limit

  // price and quantity limit from 5 digits to 10 digits

  const handleAdjustmentChange = (
    quantityValue,
    index,
    criteria,
    item_price,
    sold_price,
    processed_qty
  ) => {
    if (quantityValue.value === '') {
      setFieldValue('items[' + index + '].adjustment', '');
      setFieldValue('items[' + index + '].quantity', 0);
      calcDeduction((prev) => !prev);
      return;
    }
    let quantity = quantityValue.floatValue,
      adjustment = quantity,
      calc_quantity = quantity;
    if (criteria === 'amount' && quantity > 0) {
      quantity = `${quantity}`;
      calc_quantity = quantity.length > 10 ? quantity.slice(0, 10) : quantity;
      calc_quantity = parseFloat(calc_quantity);
    } else if (criteria === 'percent') {
      let val = item_price === sold_price ? item_price : sold_price;
      let result = (val / 100) * quantity;
      calc_quantity = (result * processed_qty).toFixed(2);
      adjustment = quantity > 100 ? 100 : quantity;
    }
    if (criteria === 'amount' || criteria === 'percent') {
      setFieldValue('items[' + index + '].adjustment', adjustment);
      setFieldValue('items[' + index + '].quantity', calc_quantity);
    }
    setFieldValue(`items[${index}].adjustment_updated`, true);
    quantityValue.value !== '' && calcDeduction((prev) => !prev);
  };

  const getvals = () => {
    if (itemsList.length === 0) {
      setAddItem(false);
    }
    setWarehouseNames([]);
    if (yikes[0] !== null) {
      for (let i = 0; i < yikes.length; i++) {
        for (let j = 0; j < warehouseList.length; j++) {
          if (yikes[i] === warehouseList[j].id) {
            let arr = [];
            arr = warehouseNames;
            arr.push(warehouseList[j].name);
            setWarehouseNames(arr);
          }
        }
      }
    } else {
      for (let i = 0; i < values.items.length; i++) {
        for (let j = 0; j < warehouseList.length; j++) {
          if (genericWarehouseId === warehouseList[j].id) {
            let arr = [];
            arr = warehouseNames;
            arr.push(warehouseList[j].name);
            setWarehouseNames(arr);
          }
        }
      }
    }

    let arr = [];
    arr = warehouseNames;
    arr.splice(yikes.length);
    setWarehouseNames(arr);
    // setAddItem(false)
  };

  // RemHere
  const handleRemoveItem = (removeIndex, currentItem) => {
    // setItemDeleteFlag(true);
    // setWarehouseNamesFlag(true);
    setAddItem(true);
    let updatedItems = values.items.filter((_, index) => index !== removeIndex);
    setFieldValue('items', updatedItems);
    calcDeduction((prev) => !prev);
    packageFakeItem = false;
    if (!currentItem.id) return;

    setStatus({
      ...status,
      itemsList: [...status.itemsList, currentItem],
    });
  };

  const [directSoldItem, setDirectSoldItem] = React.useState(false);
  // const [setflag] = React.useState(false);
  const [directSoldItemsFlagEnhancer] = React.useState(directSoldItemsFlag);
  //const [setDirectSoldItemsFlagEnhancer] = React.useState(directSoldItemsFlag);
  const [directSoldItemsArrayEnhancer] = React.useState(directSoldItemsArray);
  //const [setdirectSoldItemsArrayEnhancer] = React.useState(directSoldItemsArray);
  //const [setWarehouseChangeFlag] = React.useState(false);
  // const [setItemDeleteFlag] = React.useState(false);
  // const [setWarehouseNamesFlag] = React.useState(false);
  const [addItem, setAddItem] = React.useState(false);
  const [warehouseList, setWarehouseList] = React.useState({});
  const [warehouseNames, setWarehouseNames] = React.useState([]);
  const [yikes, setyikes] = React.useState([]);
  //const [salesOrderListEnhancer, setSalesOrderListEnhancer] = React.useState(salesOrderList);
  //const [contactPersonIdEnhancer, setContactPersonIdEnhancer] = React.useState(contactPersonId);
  //const [setSaleReturnIdEnhancer] = React.useState(0);
  const [setBackToStock] = React.useState(false);
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const [loadingFlag] = React.useState(false);
  //const [setLoadingFlag] = React.useState(false);
  const [deduction, calcDeduction] = React.useState(false);
  const [total, totalDeduction] = React.useState(0);
  const [credits, setCredits] = React.useState(0);
  const [returnReceiveItemDetailsArray, setReturnReceiveItemDetailsArray] =
    React.useState(values.items);
  useEffect(() => {
    // returnReceiveItemDetailsArray= values.items
    allResult = alResult;
    isSaleOrderEnhancer = isSaleOrder;
    if (preferences) {
      setFieldValue('transactionDiscount', preferences?.adjustment);
      setFieldValue(
        'transactionDiscountType',
        preferences?.adjustement_criteria
      );
    }
    if (isSaleOrder === true) {
      setFieldValue('salesOrderIdGeniuine', saleOrdersList[0].id);
      setFieldValue('salesreturn', salesReturnList[0].id);
      setFieldValue('returnreceive', returnReceivesList[0].id);
      saleOrdersListEnhancer = saleOrdersList;
      returnReceivesListEnhancer = returnReceivesList;
      salesReturnListEnhancer = salesReturnList;
    }
    addBackToStock && setBackToStock(addBackToStock);
    values.customerNotes = returnReason;
    // =returnReason
    setWarehouseList(warehouseResult);
    setyikes(warehouseListRecieve);
    // setflag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    allAdjustments = 0;
    values.items.forEach((el) => {
      allAdjustments = allAdjustments + Number(el.quantity);
    });
    total_Deduction = allAdjustments;
    totalDeduction(allAdjustments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deduction]);

  useEffect(() => {
    values.items.forEach((item, index) => {
      let obj = {
        floatValue: item.adjustment,
        formattedValue: `${item.adjustment}`,
        value: `${item.adjustment}`, //this is for adding first time the quantity
      };
      handleAdjustmentChange(
        obj,
        index,
        item.criteria,
        item.item_price,
        item.sold_price,
        item.actualQuantity
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.items]);
  useEffect(() => {
    calcDeduction(true);
    if (checkItemsStart() === false) {
      setFieldValue('isSubmitting', false);
      setFormSubmitting(false);
    } else {
      setFormSubmitting(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    total_Credits = Number(values?.invoice?.total_issue_able_credits - total);
    setCredits(Number(values?.invoice?.total_issue_able_credits - total));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total, values?.invoice?.total_issue_able_credits]);

  useEffect(() => {
    if (isSaleOrderEnhancer === true && type !== 'edit') {
      allResult.invoice.saleOrder.forEach((salesorder) => {
        if (salesorder.id === values.salesOrderIdGeniuine) {
          salesorder.saleReturn.forEach((salesreturn) => {
            if (salesreturn.id === values.salesreturn) {
              salesreturn.return_receives.forEach((returnreceive) => {
                if (returnreceive.id === values.returnreceive) {
                  const resItems = returnreceive.returnReceivesDetails.map(
                    (item, index) => {
                      if (
                        allResult.invoice.invoice_details[index] === undefined
                      ) {
                      }
                      return {
                        id: item.item_id,
                        salesOrderItemDetailId: item.id,
                        dragDropID: GetDateString(),
                        name: item.items.name,
                        images: item.items.images,
                        sku: item.items.sku,
                        unit: item.items.unit,
                        inventoryType: item.items.inventory_type,
                        ordered: item.return_quantity_added,
                        /*warehouse_idd: allResult.invoice.invoice_details[index].warehouse_id
                          ? allResult.invoice.invoice_details[index].warehouse_id : null,*/
                        /*res_warehouse_id: allResult.invoice.invoice_details[index].warehouse_id
                          ? allResult.invoice.invoice_details[index].warehouse_id : null,*/
                        warehouse_idd:
                          item.target_warehouse_id !== null
                            ? item.target_warehouse_id
                            : item.warehouse_id,
                        res_warehouse_id:
                          item.target_warehouse_id !== null
                            ? item.target_warehouse_id
                            : item.warehouse_id,
                        packed: item.packed,
                        shipped: item.shipped,
                        stocks:
                          // item.items.stocks.find(
                          //   (stock) =>
                          //     stock.warehouse_id ===
                          //     (item.target_warehouse_id !== null
                          //       ? item.target_warehouse_id
                          //       : item.warehouse_id)
                          // ) ||
                          {},
                        quantity: 0,
                        quantity_processed:
                          type !== 'edit'
                            ? item.quantity
                            : item.quantity_processed,
                        price: item.items.sales_unit_price,
                        sold_price:
                          allResult.invoice.saleOrder[0].sales_order_details[
                            index
                          ].rate, //fix the price error
                        actualQuantity: item.quantity,
                        adjustment: preferences?.adjustment,
                        criteria: preferences?.adjustement_criteria,
                      };
                    }
                  );
                  setReturnReceiveItemDetailsArray(resItems);
                  setValues({
                    ...values,
                    items: [...resItems],
                  });
                }
              });
            }
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.salesOrderIdGeniuine, values.salesreturn, values.returnreceive]);

  function checkItemsStart() {
    const { items } = values;
    var ItemPassed = true;
    items.forEach((item) => {
      if (item.quantity_processed <= 0) {
        ItemPassed = false;
        return ItemPassed;
      }
    });
    return ItemPassed;
  }

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(values.items);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setFieldValue('items', items);
  };

  const handleArrowsKey = (e, index, fieldname) => {
    if (e.key === 'ArrowDown' && index + 1 < values?.items?.length) {
      const element = document.getElementById(
        `items[${index + 1}].${fieldname}`
      );
      setTimeout(() => {
        element.select();
        element.scrollIntoView({ block: 'nearest', inline: 'nearest' });
      }, 100);
    }
  };

  const handleArrowUpKey = (e, index, fieldname) => {
    if (e.key === 'ArrowUp' && index - 1 >= 0) {
      const element = document.getElementById(
        `items[${index - 1}].${fieldname}`
      );
      element.select();
      element.scrollIntoView({ block: 'nearest', inline: 'nearest' });
    }
  };

  const renderSelectedItemsTable = () => {
    const { items } = values;
    if (warehouseList.length > 0) {
      for (let i = 0; i < items.length; i++) {
        for (let j = 0; j < warehouseList.length; j++) {
          if (items[i].warehouse_idd === warehouseList[j].id) {
            items[i].warehouse_idd = warehouseList[j].name;
          }
        }
      }
    }
    // resShipmentId = shipmentId;
    return (
      <div className="so-tbletabs">
        {loadingFlag && <Loader />}
        <table className="list-table form-table border horizontal-items-table add_credit_note w-100">
          <thead className="table-header">
            <tr className="table--row">
              <th>Items</th>
              <th className="table--row">Warehouse</th>
              <th className="table--row">Price</th>
              <th className="table--row">Qty.</th>
              <th className="table--row">Processed Qty.</th>
              <th className="table--row deduction-field">Deduction Policy</th>
              <th className="table--row pd-left">Deductions</th>
            </tr>
          </thead>
          {/* <tbody onLoad={getvals()}> */}
          <DragDropItems handleOnDragEnd={handleOnDragEnd} onLoad={getvals()}>
            {items.map((item, index) => {
              return (
                <Draggable
                  key={item.dragDropID}
                  draggableId={item.dragDropID}
                  index={index}
                >
                  {(provided) => (
                    <tr
                      key={index}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <td className={item.id ? '' : 'no padding'}>
                        <div className="__dragDrop">
                          <div
                            {...provided.dragHandleProps}
                            className={
                              values.items.length >= 2
                                ? '__dragDrop_iconParent'
                                : '__dragDrop_iconParent notDisplay'
                            }
                            tabIndex="-1"
                          >
                            <MdDragIndicator />
                          </div>
                          <div className="__drag-righ">
                            {item.id ? (
                              <ListTableImageColumn
                                name={item.name}
                                images={item.images}
                                sku={item.sku}
                                id={item.id}
                                showModalUponClick={inModal ? false : true}
                              />
                            ) : (
                              <Field
                                type="dropdown"
                                value={item.id}
                                options={itemsList}
                                dropdownValue="id"
                                dropdownLabel="name"
                                withImage={true}
                                placeholder="Select Item"
                                additionalSearchFilter="sku,upc,mpn,ean,isbn"
                                clearable={false}
                                handleChange={(option) =>
                                  handleSelectedItem(option, index)
                                }
                                error={getError(
                                  errors,
                                  touched,
                                  `items[${index}].name`
                                )}
                                onBlur={() =>
                                  setFieldTouched(`items[${index}].name`, true)
                                }
                              />
                            )}
                          </div>
                        </div>
                      </td>
                      <td className="">
                        <Field
                          size="large"
                          id="warehouseId"
                          value={
                            typeof item.warehouse_idd === 'string'
                              ? item.warehouse_idd
                              : ''
                          }
                          disabled={true}
                          clearable={false}
                          required
                          error={touched.warehouseId && errors.warehouseId}
                        />
                        {
                          <div className="table-extra-data right-align">
                            {item.inventoryType === 'noninventory'
                              ? 'Un-tracked item'
                              : ''}
                          </div>
                        }
                      </td>
                      <td className="list-table--amount">
                        <div style={{ width: '100%' }}>
                          {item.sold_price !== item.price && 'Curr.:'}{' '}
                          {currency.symbol}
                          {item.price}
                        </div>
                        {item.sold_price !== item.price && (
                          <div
                            style={{
                              width: '100%',
                              color: 'Grey',
                              fontSize: '10px',
                            }}
                          >
                            Sold at: {currency.symbol}
                            {item.sold_price}
                          </div>
                        )}
                      </td>
                      <td className="">
                        <Quantity
                          quantity={item.actualQuantity}
                          unit={item.unit}
                        />
                      </td>
                      <td>
                        <Field
                          size="small"
                          type="number"
                          value={item.quantity_processed}
                          decimalScale={0}
                          disabled={!item.salesOrderItemDetailId ? false : true}
                          id={`items[${index}].quantity_processed`}
                          allowNegative={false}
                          thousandSeparator=","
                          onValueChange={(value) => {
                            handleProcessedItemQuantityChange(
                              value,
                              index,
                              item
                            );
                            handleShowPrompt();
                          }}
                          error={
                            (touched &&
                              touched.items &&
                              touched.items[index] &&
                              touched.items[index].quantity_processed &&
                              item.quantity_processed === 0 &&
                              'Can`t be zero') ||
                            (touched &&
                              touched.items &&
                              touched.items[index] &&
                              touched.items[index].quantity_processed &&
                              item.quantity_processed > item.actualQuantity &&
                              "Can't be greater than quantity")
                          }
                          onBlur={handleBlur}
                          onKeyDown={(e) =>
                            handleArrowsKey(e, index, 'quantity_processed')
                          }
                          onKeyUp={(e) =>
                            handleArrowUpKey(e, index, 'quantity_processed')
                          }
                        />
                      </td>
                      <td
                        className="discount_switch"
                        style={{ padding: '0px' }}
                      >
                        <Field
                          size="xs"
                          type="number"
                          value={parseFloat(item.adjustment).toFixed(2)}
                          // value={item.adjustment}
                          decimalScale={2}
                          placeholder="0"
                          id={`items[${index}].adjustment`}
                          rightData={
                            <Switch
                              options={getDiscountOptions(currency)}
                              value={item.criteria}
                              handleChange={(option) => {
                                setFieldValue(
                                  `items[${index}].criteria`,
                                  option.value
                                );
                                setFieldValue(
                                  'items[' + index + '].adjustment',
                                  ''
                                );
                              }}
                            />
                          }
                          removeValueOnClick
                          allowNegative={false}
                          onValueChange={(value) => {
                            // setFieldValue("items["+index+"].adjustment", value.floatValue)
                            handleAdjustmentChange(
                              value,
                              index,
                              item.criteria,
                              item.price,
                              item.sold_price,
                              item.quantity_processed
                            );
                          }}
                          thousandSeparator=","
                          onBlur={handleBlur}
                          onKeyDown={(e) =>
                            handleArrowsKey(e, index, 'adjustment')
                          }
                          onKeyUp={(e) =>
                            handleArrowUpKey(e, index, 'adjustment')
                          }
                        />
                      </td>
                      <td className="no-padding with-close--btn">
                        <Field
                          size="small"
                          type="number"
                          disabled={true}
                          value={item.quantity}
                          decimalScale={2}
                          id={`items[${index}].quantity`}
                          allowNegative={false}
                          thousandSeparator=","
                          onValueChange={(value) =>
                            handleItemQuantityChange(value, index)
                          }
                          /*error={
                          // touched && touched.items && touched.items[index] && touched.items[index].quantity &&item.quantity <= 0 && "Can`t be zero"
                          touched && touched.items && touched.items[index] && touched.items[index].quantity
                        }*/
                          onBlur={handleBlur}
                        />

                        {item.salesOrderItemDetailId == null && (
                          <button
                            type="button"
                            disabled={items.length === 1}
                            className="close-btn button"
                            onClick={() => handleRemoveItem(index, item)}
                          >
                            <DeleteIcon className="bin" />
                          </button>
                        )}
                      </td>
                    </tr>
                  )}
                </Draggable>
              );
            })}
          </DragDropItems>

          {/* </tbody> */}
        </table>
      </div>
    );
  };

  return (
    <Form
      title={title}
      className="package-form"
      onSubmit={(event) =>
        formSubmit(event, values.items, setFieldValue, handleSubmit)
      }
    >
      <div className="form-fields-container d-flex flex-wrap  position-relative">
        <Field
          name="Credit Note No."
          className="flex-25"
          id="creditNoteNo"
          placeholder="Credit Note No"
          value={values.creditNoteNo}
          handleChange={handleChange}
          onBlur={handleBlur}
          // disabled={type === 'edit'}
          disabled
          required
          error={touched.creditNoteNo && errors.creditNoteNo}
        />
        <Field
          type={'text'}
          name="Customer Name"
          autoFocus={true}
          id="salesOrderId"
          className="flex-25"
          // value={type === 'edit' ? contactDisplayName : contactPersonIdEnhancer}
          value={contactDisplayName}
          // options={contactPerson}
          dropdownValue="id"
          dropdownLabel="name"
          // handleChange={(option) => {
          //   setContactPersonIdEnhancer(option.id);
          //   let myOrderList = [];

          //   allContactsList.forEach((cp) => {
          //     if (option.id === cp.id) {
          //       cp.invoice_list.forEach((ol) => {
          //         let tempraryObj = {};
          //         tempraryObj['id'] = ol.id;
          //         tempraryObj['name'] = ol.invoice_no;
          //         myOrderList.push(tempraryObj);
          //       });
          //     }
          //   });
          //   if (myOrderList[0].id) setSaleReturnIdEnhancer(myOrderList[0].id);
          //   setSalesOrderListEnhancer(myOrderList);
          //   let tempObj = {};
          //   tempObj['id'] = myOrderList[0].id;
          //   tempObj['value'] = myOrderList[0].id;
          //   tempObj['name'] = myOrderList[0].name;
          //   tempObj['label'] = myOrderList[0].name;
          //   handleInvoiceChange(tempObj, isSaleOrderEnhancer);
          // }}
          // clearable={false}
          // onBlur={handleBlur}
          required
          disabled={type === 'edit' || inModal === true}
          // error={touched.salesOrderId && errors.salesOrderId}
        />
        <Field
          type="text"
          name="Invoice No."
          // id="salesOrderId"
          className="flex-25"
          disabled={type === 'edit' || inModal === true}
          value={invoiceNumber}
          // options={salesOrderListEnhancer}
          dropdownValue="id"
          dropdownLabel="name"
          // handleChange={(option) =>
          //   handleInvoiceChange(option, isSaleOrderEnhancer)
          // }
          // clearable={false}
          // onBlur={handleBlur}
          required
          // error={touched.salesOrderId && errors.salesOrderId}
        />
        {directSoldItemsFlagEnhancer && isSaleOrderEnhancer && (
          <div>
            <br />
            <input
              type="checkbox"
              className="directly_sold_pointer"
              name="directSoldItem"
              id="View"
              onChange={() => {
                setDirectSoldItem(!directSoldItem);
                if (directSoldItem === false) {
                  setValues({
                    ...values,
                    items: [...directSoldItemsArrayEnhancer],
                  });
                  calcDeduction();
                } else if (directSoldItem === true) {
                  setValues({
                    ...values,
                    items: [...returnReceiveItemDetailsArray],
                  });
                  calcDeduction();
                }
              }}
              checked={directSoldItem}
            />
            <label
              className="bold title_check directly_sold_pointer"
              onClick={() => {
                setDirectSoldItem(!directSoldItem);
                if (directSoldItem === false) {
                  setValues({
                    ...values,
                    items: [...directSoldItemsArrayEnhancer],
                  });
                  calcDeduction();
                } else if (directSoldItem === true) {
                  setValues({
                    ...values,
                    items: [...returnReceiveItemDetailsArray],
                  });
                  calcDeduction();
                }
              }}
              htmlFor="directSoldItem"
            >
              {' '}
              Directly sold items through invoice
            </label>
          </div>
        )}
        {!directSoldItem && isSaleOrderEnhancer && (
          <>
            <Field
              type="dropdown"
              name="Sales Order"
              id="salesOrderIdGeniuine"
              className="flex-25"
              value={values.salesOrderIdGeniuine}
              options={saleOrdersListEnhancer}
              disabled={type === 'edit'}
              dropdownValue="id"
              dropdownLabel="name"
              handleChange={(option) => handleSalesOrderChange(option)}
              clearable={false}
              onBlur={handleBlur}
              required
              error={touched.salesOrderId && errors.salesOrderId}
            />

            <Field
              type="dropdown"
              name="Sales Return"
              id="salesreturn"
              className="flex-25"
              value={values.salesreturn}
              options={salesReturnListEnhancer}
              dropdownValue="id"
              dropdownLabel="name"
              disabled={type === 'edit'}
              handleChange={(option) => handleSalesReturnChange(option)}
              clearable={false}
              onBlur={handleBlur}
              required
              error={touched.salesOrderId && errors.salesOrderId}
            />
            <Field
              type="dropdown"
              name="Return Receive"
              className="flex-25"
              id="returnreceive"
              value={values.returnreceive}
              options={returnReceivesListEnhancer}
              disabled={type === 'edit'}
              dropdownValue="id"
              dropdownLabel="name"
              handleChange={(option) => handleReturnReceiveChange(option)}
              clearable={false}
              onBlur={handleBlur}
              required
              error={touched.salesOrderId && errors.salesOrderId}
            />
          </>
        )}

        <Field
          type="date"
          name="Credit Note Date"
          id="salesReturnDate"
          className={` flex-25 ${
            !directSoldItemsFlagEnhancer && !isSaleOrderEnhancer
              ? 'credit-note-set'
              : 'credit-note-so'
          }`}
          // className="inline-field"
          value={values.salesReturnDate}
          minDate={values.orderDate}
          handleChange={(date) => {
            setFieldValue('salesReturnDate', date);
            // setFieldValue('sales_return_date_update', true);
          }}
          onBlur={() => setFieldTouched('salesReturnDate', true)}
          required
          error={touched.salesReturnDate && errors.salesReturnDate}
        />
      </div>
      {/* <div className="form-field form-table--container"> */}
      {values.items.length >= 1 && values.items[0].id !== null && (
        <div className="form-field form-table--container mr-0">
          <label className="form-label">Returnable Items</label>
          <FieldArray name="items" render={() => renderSelectedItemsTable()} />
          {addItem && <React.Fragment>{renderAddItem()} </React.Fragment>}
        </div>
      )}
      {renderFooter()}
      <FormAction
        disabled={
          formSubmitting === false ||
          credits <= 0 ||
          errors.salesOrderId ||
          isSubmitting
        }
        marginFromRight={0}
        onCancel={() => {
          let dataFromLS = getObjFromLS('module');
          let redirectUrl = '';
          if (dataFromLS && dataFromLS.moduleName === 'creditnotes') {
            redirectUrl = dataFromLS.queryParam;
          }
          if (inModal) {
            let response;
            const { checkout } = validateCreditNotesForm(
              values,
              {
                ...staticVal,
                ...(inModal && {
                  salesOrderIdGeniuine: saleOrdersList?.[0].id,
                }),
                ...(inModal && { salesreturn: salesReturnList?.[0].id }),
                ...(inModal && { returnreceive: returnReceivesList?.[0]?.id }),
                transactionDiscount: preferences?.adjustment,
                transactionDiscountType: preferences?.adjustement_criteria,
              },
              inModal
            );
            if (!checkout) {
              response = confirmationAlert();
            }
            if (response || checkout) {
              close();
            }
          } else if (window.location.pathname.includes('creditnotes')) {
            setObjInLS('module', { ...dataFromLS, action: null });
            history.push('/r');
          } else if (
            window.location.pathname.includes('add') ||
            window.location.pathname.includes('edit')
          ) {
            history.push('/creditnotes' + redirectUrl);
          } else {
            setObjInLS('module', { ...dataFromLS, action: null });
            history.push('/r');
          }
        }}
      />
      <ErrorFocus />
    </Form>
  );
};

export default formEnhancer(CreditNoteForm);

import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { NewInvoiceIcon } from '../../../../Assets/Navigation/NavigationIcons';
import AttachIcon from '../../../../Assets/General/AttachmentIcon';

import { restRequest } from '../../../../Helpers/RequestHelper';
import getOverallTaxDetails from '../../../../Helpers/GetOverallTaxDetails';
import { getOrganizationDate } from '../../../../Helpers/Date/OrganizationDate';

import PDFViewer from '../../../../Components/PDFViewer/PDFViewer';
import InvoiceSummary from './Summary/InvoiceSummary';
import InvoiceInfo from './Info/InvoiceInfo';
import Loader from '../../../../Components/Loader/Loader';
import HeaderWrapper from '../../../../Components/HeaderWrapper/HeaderWrapper';
import { GridLayout } from '../../../../Components/Layout/Layout';
import { ActionMenu } from '../../../../Components/CheckedMenu/CheckedMenu';
import Box from '../../../../Components/Layout/Box';
import TermsCondition from '../../../../Components/Terms/Terms';
import CustomModal from '../../../../Components/CustomModal/CustomModal';
import NotFound from '../../../../Components/NotFound/NotFound';
import Payments from './Tabs/Payments/Payments';
import CreditNotes from './Tabs/CreditNotes/CreditNotes';
import pageIdentify from '../../../../Helpers/PageIdentify';
import { getObjFromLS, setObjInLS } from '../../../../Helpers/LocalStorage';
import AccessDenied from '../../../../Components/AccessDenied/AccessDenied';
import { controllerNames } from '../../../../Constants';
import MarkAsSentIcon from '../../../../Assets/General/MarkAsSentIcon';
import RecordPaymentForm from '../../../../Components/Form/RecordPaymentForm/RecordPaymentForm';
import Menu from '../../../../Components/Menu/Menu';
import MenuData from '../../../../Components/Menu/MenuData';
import History from '../../../../Assets/General/History';
import RecordPayment from '../../../../Assets/General/RecordPaymentIcon';
import '../../../SalesOrder/Invoices/InvoiceCreate.css';
import { CreditNoteIcon } from '../../../../Assets/Navigation/NavigationIcons';
import DeleteAttachmentIcon from '../../../../Assets/General/DeleteAttachmentIcon';
import DownloadAttachmentIcon from '../../../../Assets/General/DownloadAttachmentIcon';
import OverlayLoader from '../../../../Components/Loader/OverlayLoader';
import getCurrentMenu from '../../../../Helpers/getCurrentMenu';
import { pdfDownlaod } from '../../../../Helpers/PdfDownload';
import { downloadAttachment } from '../../../../Helpers/downloadAttachment';
import { checkError } from '../../../../Helpers/AuthHelper';
import Notes from '../../../../Components/Notes/Notes';
import { HeaderMenus } from '../../../../Components';
import useMobileDetection from '../../../../Hooks/MobileDetect';
import { UtilizationFromInvoice } from '../../../../Components/Form';
import SycnOrderDetails from '../../../Items/ItemDetails/SyncOrderDetials';
class InvoiceDetails extends Component {
  id = this.props.id
    ? this.props.id
    : // : this.props.idFromHeaderWrapper
      // ? this.props.idFromHeaderWrapper
      this.props?.match?.params?.id;
  state = {
    invoiceInfo: {},
    activityLog: [],
    detailsNo: '',
    currency: {},
    loading: true,
    processingAttachment: false,
    processignPdf: false,
    notFound: false,
    accessDenied: false,
    clickedId: '',
    showMarkAsVoidModal: false,
    ReceivePaymentCreateModal: false,
    showCreditNotePaymentModal: false,
    showPDF: false,
    pdf: null,
    markAsVoidDisabledFlag: [],
    invoiceDetailsForWarehouses: {},
    warehouseList: {},
    attachments: [],
    type: '',
    currentMenu: getCurrentMenu(window.location.search),
    showFileDelteModal: false,
    attachmentId: null,
    discountPreferance: {},
    showCreditNotesTab: false,
    showPrompt: false,
    total_unusedcredits: null,
    showPaymentViaCreditsButton: false,
  };

  componentDidMount() {
    const value = getObjFromLS('module');
    if (value && value.id && value.moduleName === 'invoices') {
      this.id = value.id;
    }
    document.title = 'Invoice Details';
    this.fetchData();
  }

  handlePrompt = (status = true) => {
    this.setState({
      showPrompt: status,
    });
  };

  fetchData = async () => {
    this.setState({ loading: true });
    let getInvoiceDetails = () => {
      return restRequest('get', `invoices/${this.id}`);
    };
    // let getInvoiceActivities = () => {
    //   return restRequest('get', `invoices/${this.id}/activity-log`);
    // };
    let getCreditNotesTab = () => {
      return restRequest('get', `invoices/${this.id}/creditnotes`);
    };

    let requests = (await this.hasViewPermission('creditnotes'))
      ? [getInvoiceDetails(), getCreditNotesTab()]
      : [getInvoiceDetails()];
    Promise.all(requests)
      .then((res) => {
        let invoiceDetails = res.reduce((details, values) => {
          return { ...details, ...values };
        });
        let invoiceInfo = invoiceDetails.invoice_info;
        let currency = invoiceDetails.base_currency;
        // let activityLog = invoiceDetails.activity_log;
        this.setState({
          invoiceInfo: {
            note: invoiceInfo.note,
            billingAddress: invoiceInfo.billing_address,
            dueDate: invoiceInfo.due_date,
            amount: invoiceInfo.amount,
            invoiceDate: invoiceInfo.invoice_date,
            invoiceTermName: invoiceInfo.invoice_term_name,
            invoiceNo: invoiceInfo.invoice_no,
            paymentDue: invoiceInfo.payment_due,
            salesOrderId: invoiceInfo.sales_orders[0]?.id,
            salesOrderNos: invoiceInfo.sales_orders.map(
              (so) => so.sales_order_no
            ),
            status: invoiceInfo.status,
            originalStatus: invoiceInfo.original_status,
            customer: invoiceInfo.customer,
            customer_name: invoiceInfo?.display_name
              ? invoiceInfo?.display_name
              : invoiceInfo?.customer?.display_name,
            orderNo: invoiceInfo.order_no,
            items: invoiceInfo.invoice_details,
            total: invoiceInfo.total,
            adjustment: invoiceInfo.adjustment,
            shippingCharge: invoiceInfo.shipping_charge,
            discountLevel: invoiceInfo.discount_level,
            discountTransactionLevel: invoiceInfo.discount_transaction_level,
            discountType: invoiceInfo.discount_type,
            discount: invoiceInfo.discount,
            payment_made: invoiceInfo.payment_made,
            payment_via_credits: invoiceInfo.payment_via_credits,
            salesPerson: invoiceInfo.sales_person
              ? invoiceInfo.sales_person.name
              : null,
            terms_and_condition: invoiceInfo.terms_and_condition,
            sync_order: invoiceInfo?.order_association?.length || false,
            sync_order_details: invoiceInfo?.order_association?.length
              ? invoiceInfo?.order_association[0]
              : null,
          },
          currency,
          attachments: invoiceInfo.attachments,
          markAsVoidDisabledFlag: invoiceInfo.credit_notes,
          detailsNo: invoiceInfo.invoice_no,
          // activityLog,
          invoiceDetailsForWarehouses:
            invoiceDetails.invoice_info.invoice_details,
          warehouseList: invoiceDetails?.warehouses,
          showCreditNotesTab:
            this.hasViewPermission('creditnotes') &&
            invoiceDetails.credit_notes.length > 0
              ? true
              : invoiceDetails.show_add_button,
          loading: false,
          total_unusedcredits: invoiceDetails.total_unusedcredits,
          showPaymentViaCreditsButton: invoiceDetails.utilizeableCredits,
        });
        let dataFromLS = getObjFromLS('module');
        if (
          dataFromLS &&
          dataFromLS.queryParam &&
          dataFromLS.queryParam.includes(this.state.currentMenu)
        ) {
          let query = dataFromLS.queryParam;
          query = query.replace(`&${this.state.currentMenu}=true`, '');
          query = query.replace(`?${this.state.currentMenu}=true`, '');
          setObjInLS('module', {
            ...dataFromLS,
            queryParam: query,
            presistDetailPage: true,
          });
          if (window.history.pushState) {
            let newurl =
              window.location.protocol +
              '//' +
              window.location.host +
              window.location.pathname +
              query;
            window.history.pushState({ path: newurl }, '', newurl);
          }
        } else {
          setObjInLS('module', {
            ...dataFromLS,
            presistDetailPage: true,
          });
        }
      })
      .catch((err) => {
        let dataFromLS = getObjFromLS('module');
        if (dataFromLS && dataFromLS.action && dataFromLS.action === 'delete') {
          checkError(err);
        } else if (err.response?.data?.message !== 'Invoice not found.') {
          checkError(err, this.props.handleToast);
        }
        //console.log('------', err.response.status)
        if (err.response && err.response.status === 403) {
          //console.log('i m here')
          this.setState({
            loading: false,
            accessDenied: true,
          });
        } else {
          this.setState({
            loading: false,
            notFound: true,
          });
        }
      });
  };

  updateInvoice = () => {
    if (!pageIdentify(window.location.pathname)) {
      this.props.history.push('/r');
    } else this.fetchData();
  };

  openMarkAsVoidModal = (id) => {
    this.setState({
      showMarkAsVoidModal: true,
      clickedId: id,
    });
  };

  closeAllModals = () => {
    this.setState({
      showMarkAsVoidModal: false,
      ReceivePaymentCreateModal: false,
      showPDF: false,
      showCreditNotePaymentModal: false,
      pdf: null,
    });
  };

  openPdf = (id) => {
    const [hasMobile] = useMobileDetection();
    if (hasMobile) {
      window.open(`/print/${id}/invoices`, '_blank');
    } else {
      this.setState({ showPDF: true });
      restRequest('get', `invoices/${id}/pdf`)
        .then((res) => {
          this.setState({ pdf: res });
        })
        .catch((error) => checkError(error, this.props.handleToast));
    }
  };

  markAsSent = (id) => {
    this.setState({ processing: true });
    restRequest('put', `invoices/${id}/sent`)
      .then(() => {
        this.setState({ processing: false });
        // check if overdue
        if (pageIdentify(window.location.pathname)) {
          let status = this.state.invoiceInfo.total === 0 ? 'paid' : 'sent';
          let originalStatus = status;
          let currentOrgTime = moment(getOrganizationDate());
          if (
            currentOrgTime.isAfter(this.state.invoiceInfo.dueDate) &&
            status === 'sent'
          ) {
            status = 'overdue';
          }
          this.setState({
            invoiceInfo: {
              ...this.state.invoiceInfo,
              status,
              originalStatus,
            },
          });
        } else {
          this.props.history.push('/r');
        }
        this.props.handleToast('Invoice marked as sent.', 'success');
      })
      .catch((error) => {
        this.setState({ processing: false });
        checkError(error, this.props.handleToast);
      });
  };

  markAsVoid = () => {
    restRequest('put', `invoices/${this.state.clickedId}/void`)
      .then(() => {
        if (pageIdentify(window.location.pathname)) {
          this.props.handleToast('Invoice marked as Void.', 'success');
          this.setState({
            invoiceInfo: {
              ...this.state.invoiceInfo,
              status: 'void',
              originalStatus: 'void',
            },
          });
        } else {
          this.props.history.push('/r');
        }
        this.closeAllModals();
      })
      .catch((error) => checkError(error, this.props.handleToast));
  };
  hasPermissionCustom(name, type) {
    let permissions = getObjFromLS('role').permissions;
    if (permissions.superAccess) {
      if (type === 'All') return [true, true, true];
      return true;
    }

    if (!['Create', 'Edit', 'Delete', 'All'].includes(type))
      throw new Error('Type must be one of Create, Edit, Delete, All');
    if (!(name in controllerNames))
      throw new Error('Invalid name is receieved for permission check');
    if (type !== 'All') {
      return permissions[`${controllerNames[name]}${type}`];
    }
    return [
      permissions[`${controllerNames[name]}Create`],
      permissions[`${controllerNames[name]}Edit`],
      permissions[`${controllerNames[name]}Delete`],
    ];
  }
  renderMarkAsVoidModal() {
    return (
      <CustomModal
        showModal={this.state.showMarkAsVoidModal}
        title="Confirm to Mark as Void"
        onClose={this.closeAllModals}
        onSubmit={() => this.markAsVoid()}
      >
        Are you sure you want to mark this invoice as Void?
      </CustomModal>
    );
  }

  populateConditionalOptions(id, status, creditNotesCheck) {
    const hasEditPermission = this.hasPermission('invoice', 'Edit');
    const options = [];
    if (!hasEditPermission) return options;

    if (status === 'draft') {
      options.push({
        label: 'Mark as Sent',
        icon: MarkAsSentIcon,
        onClick: () => this.markAsSent(id),
      });
    }
    /*if (status === 'draft' || status === 'sent') {
      if (creditNotesCheck.length === 0) {
        options.push({
          label: 'Mark as Void',
          icon: MarkAsVoidIcon,
          onClick: () => this.openMarkAsVoidModal(id)
        })
      }
    }*/

    return options;
  }

  getSubtotal(itemsList) {
    return itemsList.reduce(
      (total, item) =>
        total + parseFloat((item.quantity * item.rate).toFixed(2)),
      0
    );
  }

  isInvoiceEditable() {
    return !(
      this.state.invoiceInfo.originalStatus === 'void' ||
      (this.state.invoiceInfo.originalStatus === 'paid' &&
        !this.state.invoiceInfo.total !== 0) ||
      this.state.invoiceInfo.originalStatus === 'partially paid' ||
      this.state.invoiceInfo.originalStatus === 'sent'
    );
  }

  async createReceivePayment() {
    this.setState({ ReceivePaymentCreateModal: true });
  }
  renderReceivePaymentCreateModal() {
    return (
      <CustomModal
        Icon={RecordPayment}
        showModal={this.state.ReceivePaymentCreateModal}
        title="New Receive Payment"
        renderActions={false}
        size="large"
        onClose={this.closeAllModals}
        classNames="new-recieve-payment-popup"
        showPrompt={this.state.showPrompt}
        // extraClass="invoices-large"   //commenting this to remove extra space from modal. Ticket related
      >
        <RecordPaymentForm
          inModal
          type="invoice"
          id={this.id}
          history={this.props.history}
          handleToast={this.props.handleToast}
          onSubmit={this.updateInvoice}
          onClose={this.closeAllModals}
          handlePrompt={this.handlePrompt}
        />
      </CustomModal>
    );
  }
  handleAddAttachment(event) {
    document.body.style.cursor = 'wait';
    const { handleToast } = this.props;
    let selectedFiles = Array.from(event.target.files);
    if (selectedFiles.length > 10) {
      handleToast('Cannot add more than 10 attachments', 'error');
      setTimeout(() => {
        document.body.style.cursor = 'default';
        this.setState({ processingAttachment: false });
      }, 2000);
      return null;
    }
    let discardedFiles = [];
    let newFiles = selectedFiles.filter((file) => {
      const MAX_FILE_SIZE = 5; // max file size = 5MB
      let fileSize = file.size / 1000 / 1000; // converted from bytes to MB
      if (fileSize > MAX_FILE_SIZE) {
        discardedFiles.push(file);
      }
      return fileSize <= MAX_FILE_SIZE;
    });
    if (!!discardedFiles.length) {
      let errorMessage = discardedFiles.map((file) => {
        return `${file.name} exceeds the file size limit`;
      });
      handleToast(errorMessage, 'error');
    }

    const fd = new FormData();
    let newFileCount = this.state.attachments.length;
    newFiles.forEach((file, index) => {
      if (newFileCount <= 9) {
        fd.append(`files[${index}]`, file);
        newFileCount++;
      } else {
        // break;
        handleToast('Cannot add more than 10 attachments', 'error');
        return;
      }
    });
    fd.append('subjectId', this.id);
    fd.append('name', this.state.invoiceInfo.invoiceNo);
    this.setState({ processingAttachment: true });
    restRequest('post', 'invoices/attachments', fd)
      .then(() => {
        handleToast('Attachment added successfully', 'success');
        this.fetchData();
        document.body.style.cursor = 'default';
        this.setState({ processingAttachment: false });
      })
      .catch((errorMessage) => {
        document.body.style.cursor = 'default';
        checkError(errorMessage, handleToast);
        //handleToast(errorMessage, "error")
        this.setState({ processingAttachment: false });
      });
  }

  async downloadSingleFile(attachment) {
    this.setState({ processingAttachment: true });
    await downloadAttachment(
      `invoices/${attachment.id}/download-attachment`,
      this.props.handleToast,
      'single'
    );
    this.setState({ processingAttachment: false });
  }

  async downloadAllFiles() {
    this.setState({ processingAttachment: true });
    await downloadAttachment(
      `invoices/${this.id}/download-all`,
      this.props.handleToast,
      'multiple'
    );
    this.setState({ processingAttachment: false });
  }

  deleteAllFiles() {
    this.setState({ processingAttachment: true, showFileDelteModal: false });
    document.body.style.cursor = 'wait';
    restRequest('delete', `invoices/attachment/delete_all/${this.id}`)
      .then((res) => {
        document.body.style.cursor = 'default';
        this.props.handleToast(`Attachment deleted successfully`, 'success');
        this.fetchData();
        this.setState({
          showFileDelteModal: false,
          attachmentId: null,
          type: '',
        });
        this.setState({ processingAttachment: false });
      })
      .catch((error) => {
        document.body.style.cursor = 'default';
        //this.props.handleToast(error, 'error')
        checkError(error, this.props.handleToast);
        this.setState({ processingAttachment: false });
      });
  }

  deleteSignleFile() {
    document.body.style.cursor = 'wait';
    this.setState({ processingAttachment: true, showFileDelteModal: false });
    restRequest('delete', `invoices/attachment/${this.state.attachmentId}`)
      .then((res) => {
        document.body.style.cursor = 'default';
        this.props.handleToast(`Attachment deleted successfully`, 'success');
        this.fetchData();
        this.setState({
          showFileDelteModal: false,
          attachmentId: null,
          type: '',
        });
        this.setState({ processingAttachment: false });
      })
      .catch((error) => {
        document.body.style.cursor = 'default';
        //this.props.handleToast(error, 'error')
        checkError(error, this.props.handleToast);
        this.setState({ processingAttachment: false });
      });
  }
  showFileDeleteModal(attId, filetype) {
    this.setState({
      showFileDelteModal: true,
      attachmentId: attId,
      type: filetype,
    });
  }
  renderFileDeleteModal() {
    return (
      <Fragment>
        <CustomModal
          type="delete"
          showModal={this.state.showFileDelteModal}
          title={`Confirm Delete`}
          onClose={() => this.setState({ showFileDelteModal: false })}
          onSubmit={() => {
            this.state.type === 'single'
              ? this.deleteSignleFile()
              : this.deleteAllFiles();
          }}
        >
          Are you sure you want to delete{' '}
          {this.state.type === 'single'
            ? 'this attachment?'
            : 'all attachments?'}
        </CustomModal>
      </Fragment>
    );
  }
  attachmentOptions() {
    const [hasCreatePermission, , hasDeletePermission] = this.hasPermission(
      'invoice',
      'All'
    );
    let obj = [];
    this.state.attachments &&
      this.state.attachments.forEach((attachment) => {
        obj.push({
          label: attachment.file_name,
          icon1: DownloadAttachmentIcon,
          id: attachment.id,
          type: 'attachment',
          onClickForIcon1: () => this.downloadSingleFile(attachment),
          icon2: hasDeletePermission && DeleteAttachmentIcon,
          onClickForIcon2: () => {
            this.showFileDeleteModal(attachment.id, 'single');
          },
        });
      });
    if (this.state.attachments.length > 0)
      obj.push({
        label: 'All Attachments',
        type: 'attachment',
        icon1: DownloadAttachmentIcon,
        icon2: hasDeletePermission && DeleteAttachmentIcon,
        onClickForIcon1: () => this.downloadAllFiles(),
        onClickForIcon2: () => {
          this.showFileDeleteModal(null, 'multiple');
        },
      });
    return [
      hasCreatePermission && {
        label: this.state.processingAttachment
          ? 'Processing...'
          : 'Attach New File',
        type: 'addNewAttachment',
        onClick: (e) => this.handleAddAttachment(e),
      },
      !hasCreatePermission &&
        this.state.attachments.length === 0 && {
          label: 'No Attachments Available',
          onClick: (e) => null,
        },
      ...obj,
    ];
  }

  renderActionMenu = () => {
    const id = this.id;
    const options = this.populateConditionalOptions(
      id,
      this.state.invoiceInfo.originalStatus,
      this.state.markAsVoidDisabledFlag
    );
    const sync_order =
      this.state.invoiceInfo?.sync_order === false ? true : false;
    const count = this.state.attachments.length;
    const [hasCreatePermission, hasEditPermission, hasDeletePermission] =
      this.hasPermission('invoice', 'All');
    const menusList = [
      {
        type: 'options',
        options: options,
        className: 'options-dropdown',
        enable: !!options.length,
      },
      {
        type: 'link',
        icon: 'edit',
        enable: this.state.loading
          ? false
          : this.isInvoiceEditable() && hasEditPermission && sync_order,
        to: `/invoices/edit/${id}`,
        tooTip: 'Edit',
        mobileLable: 'Edit',
        isMobile: true,
      },
      {
        type: 'button',
        icon: 'pdf',
        tooTip: 'Download PDF',
        isMobile: true,
        mobileLable: 'Download PDF',
        handleClick: () =>
          pdfDownlaod(
            `invoices/${id}/pdf?download=true`,
            this.state.invoiceInfo.invoiceNo,
            this.props.handleToast
          ),
      },
      {
        type: 'button',
        icon: 'print',
        isMobile: true,
        mobileLable: 'Print',
        tooTip: 'Print',
        handleClick: () => this.openPdf(id),
      },
      {
        type: 'link',
        icon: 'email',
        tooTip: 'Email',
        isMobile: true,
        mobileLable: 'Email',
        enable: hasCreatePermission,
        to: `/invoices/email/${id}`,
      },
      {
        type: 'dropdown',
        className: 'attachemnt_buttons',
        options: this.attachmentOptions(),
        // dropdownLable: 'Attachments',
        enable: true,
        attachment: true,
        attachmentCount: count,
        AttachmentIcon: AttachIcon,
      },

      this.state.invoiceInfo.status !== 'paid' &&
        this.state.invoiceInfo.status !== 'void' &&
        this.state.invoiceInfo.status !== 'partially paid' &&
        this.state.invoiceInfo.status !== 'sent' &&
        this.state.invoiceInfo.status !== 'overdue' &&
        sync_order === true && {
          type: 'button',
          icon: 'delete',
          tooTip: 'Delete',
          mobileLable: 'Delete',
          isMobile: true,
          handleClick: () =>
            this.openDeleteModal(
              id,
              this.props.forceRedirect,
              this.props.forceRedirectFlag
            ),
          enable: this.state.loading ? false : hasDeletePermission,
        },
      this.state.loading
        ? false
        : this.hasPermissionCustom('invoicepayment', 'Create') &&
          this.state.invoiceInfo.status !== 'paid' &&
          this.state.invoiceInfo.status !== 'void' &&
          this.state.invoiceInfo.total !== 0 && {
            type: 'button',
            className: 'button--secondary package_button float-left mr-3',
            handleClick: () => this.createReceivePayment(),
            label: `Receive Payment`,
            icon: 'receivePayment',
          },
      {
        type: 'button',
        className: 'button--primary float-left mr-3',
        handleClick: () => this.props.history.push(`/invoices/add`),
        label: `New`,
        icon: 'new',
      },
    ];
    return (
      <ActionMenu>
        <HeaderMenus menusList={menusList} loading={this.state.loading} />
      </ActionMenu>
    );
  };

  print() {
    this.setState({ loading: true });
    restRequest('get', `invoices/${this.id}/print`)
      .then((res) => {
        var w = window.open();
        w.document.write(res.body);
        w.document.close();
        w.focus();
        setTimeout(function () {
          w.print();
          w.close();
        }, 3000);

        this.setState({ loading: false });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(err,'error')
        this.setState({ loading: false });
      });
  }
  renderMenuForHistory() {
    return <MenuData name="History" invoiceId={this.id} url={this.url} />;
  }
  renderMenuForPayments() {
    return (
      <MenuData
        name="Payments"
        invoiceId={this.invoiceId}
        invoiceNo={this.invoiceNo}
        url={this.url}
        invoiceStatus={this.invoiceStatus}
        currency={this.currency}
        InvoiceTotal={this.InvoiceTotal}
        InvoiceDue={this.InvoiceDue}
        handleToast={this.handleToast}
        updateInvoice={this.updateInvoice}
        inModal={this.inModal}
        hasPermissionCustom={this.hasPermissionCustom}
        hasViewPermission={this.hasViewPermission}
      />
    );
  }
  renderMenuForCreditNotes() {
    return (
      <MenuData
        name="creditnotes"
        invoiceId={this.invoiceId}
        invoiceNo={this.invoiceNo}
        url={this.url}
        invoiceStatus={this.invoiceStatus}
        currency={this.currency}
        inModal={this.inModal}
        handleToast={this.handleToast}
        updateInvoice={this.updateInvoice}
        hasPermissionCustom={this.hasPermissionCustom}
        hasViewPermission={this.hasViewPermission}
      />
    );
  }

  handleTabChange(tab) {
    this.setState({ currentMenu: tab });
  }

  menus() {
    return [
      {
        id: 'history',
        label: 'History',
        invoiceId: this.id,
        url: `invoices/${this.id}/activity-log`,
        icon: () => <History />,
        onClick: (flag = true) => {
          if (flag === false) this.handleTabChange('');
          else this.handleTabChange('history');
        },
        renderContent: this.renderMenuForHistory,
      },
      {
        id: 'payments',
        label: 'Payments',
        invoiceNo: this.state.invoiceInfo.invoiceNo,
        invoiceId: this.id,
        url: `invoices/${this.id}/activity-log`,
        handleToast: this.props.handleToast,
        updateInvoice: this.updateInvoice,
        InvoiceTotal: this.state.invoiceInfo.total,
        InvoiceDue: this.state.invoiceInfo.paymentDue,
        invoiceStatus: this.state.invoiceInfo.status,
        hasPermissionCustom: this.hasPermissionCustom,
        hasViewPermission: this.hasViewPermission('invoicepayment'),
        currency: this.state.currency,
        inModal: this.props.inModal,
        icon: () => <RecordPayment />,
        onClick: (flag = true) => {
          if (flag === false) this.handleTabChange('');
          else this.handleTabChange('payments');
        },
        renderContent: this.renderMenuForPayments,
      },
      {
        id: 'creditnotes',
        label: 'Credit Notes',
        invoiceNo: this.state.invoiceInfo.invoiceNo,
        invoiceId: this.id,
        url: `invoices/${this.id}/activity-log`,
        handleToast: this.props.handleToast,
        updateInvoice: this.updateInvoice,
        invoiceStatus: this.state.invoiceInfo.status,
        hasPermissionCustom: this.hasPermissionCustom,
        hasViewPermission: this.hasViewPermission('creditnotes'),
        currency: this.state.currency,
        inModal: this.props.inModal,
        icon: () => <CreditNoteIcon fill="#000" />,
        onClick: (flag = true) => {
          if (flag === false) this.handleTabChange('');
          else this.handleTabChange('creditnotes');
        },
        renderContent: this.renderMenuForCreditNotes,
      },
    ];
  }

  menusUpdated() {
    return [
      {
        id: 'history',
        label: 'History',
        invoiceId: this.id,
        url: `invoices/${this.id}/activity-log`,
        icon: () => <History />,
        onClick: (flag = true) => {
          if (flag === false) this.handleTabChange('');
          else this.handleTabChange('history');
        },
        renderContent: this.renderMenuForHistory,
      },
      {
        id: 'payments',
        label: 'Payments',
        invoiceNo: this.state.invoiceInfo.invoiceNo,
        invoiceId: this.id,
        url: `invoices/${this.id}/activity-log`,
        handleToast: this.props.handleToast,
        updateInvoice: this.updateInvoice,
        InvoiceTotal: this.state.invoiceInfo.total,
        InvoiceDue: this.state.invoiceInfo.paymentDue,
        invoiceStatus: this.state.invoiceInfo.status,
        hasPermissionCustom: this.hasPermissionCustom,
        hasViewPermission: this.hasViewPermission('invoicepayment'),
        currency: this.state.currency,
        inModal: this.props.inModal,
        icon: () => <RecordPayment />,
        onClick: (flag = true) => {
          if (flag === false) this.handleTabChange('');
          else this.handleTabChange('payments');
        },
        renderContent: this.renderMenuForPayments,
      },
    ];
  }

  newCreditNotePaymentModal() {
    const { invoiceInfo, currency } = this.state;
    const { handleToast } = this.props;
    const { invoiceNo } = invoiceInfo;
    return (
      <CustomModal
        Icon={CreditNoteIcon}
        showModal={this.state.showCreditNotePaymentModal}
        className="AppplyCredits apply-credit-popup"
        title={`Apply Credits | ${invoiceNo}`}
        renderActions={false}
        size="large"
        onClose={this.closeAllModals}
        extraClass="apply_credit_notes" //commenting this to remove extra space from modal. Ticket related
        extraTitle={
          `Invoice Balance : ${currency.symbol}` +
          parseFloat(this.state.invoiceInfo?.paymentDue).toFixed(2)
        }
        showPrompt={this.state.showPrompt}
      >
        <UtilizationFromInvoice
          inModal
          type="invoice"
          id={this.id}
          handleToast={handleToast}
          onSubmit={this.handleRecordPaymentSubmit}
          onClose={this.closeAllModals}
          handlePrompt={this.handlePrompt}
        />
      </CustomModal>
    );
  }

  openApplyCreditsPaymentModal = () => {
    this.setState({
      showCreditNotePaymentModal: true,
    });
  };

  renderLayoutView() {
    const {
      invoiceInfo,
      currency,
      showPDF,
      pdf,
      invoiceDetailsForWarehouses,
      warehouseList,
      attachments,
      currentMenu,
      total_unusedcredits,
      showPaymentViaCreditsButton,
    } = this.state;
    const billingAddress = invoiceInfo.billingAddress || {};

    return (
      <div className="float-left w-100 position-relative invoice_section sales-return-main invoice-scroll-set">
        {this.state.processingAttachment && <OverlayLoader opacity={0.7} />}
        <Menu
          menus={
            this.state.invoiceInfo.status !== 'draft' &&
            this.state.showCreditNotesTab &&
            this.hasViewPermission('creditnotes') === true
              ? this.menus()
              : this.menusUpdated()
          }
          currentMenu={currentMenu}
          history={this.props.history}
          inModal={this.props.inModal || false}
        />
        <div className="float-left w-100 ">
          <div id="printSO" className="invoice_Details_main">
            {/* Invoice topbar */}
            {this.hasPermissionCustom('invoicepayment', 'Create') &&
              invoiceInfo.status !== 'paid' &&
              showPaymentViaCreditsButton && (
                <div className="invoice-topbar-main">
                  <div className="invoice-topbar-inner d-flex">
                    <div className="invoice-bar-left d-flex align-center">
                      <i className="far fa-credit-card-blank"></i>
                      <p>
                        Credits Available: <span>{total_unusedcredits}</span>
                      </p>
                    </div>
                    {/* Ending the invoice-bar-left */}
                    <div className="invoice-bar-right">
                      {!this.props.inModal && (
                        <button
                          className="btn-apply"
                          onClick={this.openApplyCreditsPaymentModal}
                        >
                          Apply Now
                        </button>
                      )}
                    </div>
                    {/* ending the topbar right */}
                  </div>
                  {/* Ending the topbar inner */}
                </div>
              )}
            {/* ending the invoice topbar */}
            <Fragment>
              {this.renderMarkAsVoidModal()}
              {this.renderReceivePaymentCreateModal()}
              {this.renderFileDeleteModal()}
              {this.newCreditNotePaymentModal()}
              <PDFViewer
                showPDF={showPDF}
                hidePDFModal={this.closeAllModals}
                pdf={pdf}
              />
              <GridLayout className="custom_padding" grid="1">
                <InvoiceInfo
                  invoice={invoiceInfo}
                  currency={currency}
                  billingAddress={billingAddress}
                  inModal={this.props.inModal}
                />
              </GridLayout>
              {invoiceInfo?.sync_order_details !== null ? (
                <div>
                  <SycnOrderDetails
                    synDetial={invoiceInfo?.sync_order_details}
                    salesOrder={{
                      reference: invoiceInfo?.sync_order_details?.ref,
                      salesOrderNo: invoiceInfo?.orderNo,
                    }}
                    inModal={this.props.inModal}
                  />
                </div>
              ) : null}
              <InvoiceSummary
                invoice={invoiceInfo}
                invoiceDetailsForWarehouses={invoiceDetailsForWarehouses}
                currency={currency}
                warehouseList={warehouseList}
                overallTax={getOverallTaxDetails(invoiceInfo.items)}
                subTotal={this.getSubtotal(invoiceInfo.items)}
                inModal={this.props.inModal}
              />

              <hr className="float-left w-100 mb-1 border_top_light" />
              {/*{this.renderNotes(invoiceInfo.note, invoiceInfo.invoiceTermName)}*/}
              <Notes note={invoiceInfo.note} />
              <TermsCondition term={invoiceInfo.terms_and_condition} />
              {(invoiceInfo.note || invoiceInfo.terms_and_condition) && (
                <hr className="float-left w-100 mb-1 border_top_light" />
              )}
              {attachments.length > 0 && (
                <div className="float-left w-100">
                  <div className="bottom_attachment_area">
                    <AttachIcon />{' '}
                    <span className="attachment_span">
                      {' '}
                      {attachments.length} Attachment(s) added
                    </span>
                  </div>
                </div>
              )}
              {attachments.length > 0 && (
                <hr className="float-left w-100 mb-1 border_top_light" />
              )}
            </Fragment>
          </div>
        </div>
      </div>
    );
  }

  renderNotes(note, term) {
    return (
      <Fragment>
        {!!note && (
          <div className="notes">
            <h5 className="heading">Notes</h5>
            <p>{note}</p>
          </div>
        )}
      </Fragment>
    );
  }
  renderSidebar() {
    const { handleToast } = this.props;
    const { invoiceInfo, currency } = this.state;
    return (
      <>
        <Box title="Payments">
          {!this.hasViewPermission('invoicepayment') ? (
            <AccessDenied className="small dashboard" type="section" />
          ) : (
            <Payments
              invoiceId={this.id}
              updateInvoice={this.updateInvoice}
              invoiceStatus={invoiceInfo.status}
              currency={currency}
              handleToast={handleToast}
              hasPermissionCustom={this.hasPermissionCustom}
            />
          )}
        </Box>
        <Box title="Credit Notes">
          {!this.hasViewPermission('creditnotes') ? (
            <AccessDenied className="small dashboard" type="section" />
          ) : (
            <CreditNotes
              invoiceId={this.id}
              updateInvoice={this.updateInvoice}
              invoiceStatus={invoiceInfo.status}
              currency={currency}
              handleToast={handleToast}
              hasPermissionCustom={this.hasPermissionCustom}
            />
          )}
        </Box>
      </>
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    if (this.state.notFound) return <NotFound />;
    if (this.state.accessDenied) return <AccessDenied type="section" />;
    return <Fragment>{this.renderLayoutView()}</Fragment>;
  }
}

export default HeaderWrapper(InvoiceDetails, {
  name: 'Invoices',
  tableIcon: 'general_module_icon',
  deleteName: 'Invoice',
  Icon: NewInvoiceIcon,
  baseUrl: 'invoices',
  redirectUrl: '/invoices',
  onlyMenu: true,
  showName: true,
  permissionName: 'invoice',
});
